<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title>{{ entity.title }}</v-card-title>
                <v-card-text>
                    <SiteTabLinks />
                    <v-row class="pl-3 pb-3" />
                    <v-form>
                        <div class="elevation-1 pa-4 my-4">
                            <v-row>
                                <v-col class="d-flex justify-space-between align-center">
                                    <span><b>Цветовая схема сайта</b></span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <template v-for="(scheme, index) in site_schemes">
                                    <v-col :key="`site-scheme-${index}`">
                                        <div style="display: flex; justify-content: center;">
                                            <v-checkbox 
                                                v-model="site_scheme_index"
                                                :value="`${index}-scheme`"
                                            />
                                        </div>
                                        <template v-for="(color, colorIndex) in scheme">
                                            <div :key="`site-scheme-${index}-color-${colorIndex}`" :style="`background-color: ${color}; height: 40px;`"></div>
                                        </template>
                                    </v-col>
                                </template>
                            </v-row>
                        </div>
                        <v-row>
                            <v-col>
                                <div class="mb-4" style="width: 220px; padding: 15px 10px; border-radius: 10px; background-color: #8a8a8a;">
                                    <v-img max-width="200px" v-if="style.logo" :src="imageSrc(style.logo)" />
                                </div>
                                <v-file-input
                                    label="Логотип"
                                    outlined
                                    prepend-icon="mdi-image"
                                    hide-details
                                    @change="setLogo"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-img max-width="400px" v-if="style.params.top_bg" :src="imageSrc(style.params.top_bg)" class="mb-4" />
                                <v-file-input
                                    label="Фон верхнего блока"
                                    outlined
                                    prepend-icon="mdi-image"
                                    hide-details
                                    @change="setParamImage('top_bg', $event)"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                    v-model="style.params.preview"
                                    outlined
                                    label="Краткое описание в верхнем блоке"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-img max-width="400px" v-if="style.params.detail_img" :src="imageSrc(style.params.detail_img)" class="mb-4" />
                                <v-file-input
                                    label="Изображение для описания"
                                    outlined
                                    prepend-icon="mdi-image"
                                    hide-details
                                    @change="setParamImage('detail_img', $event)"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                    v-model="style.params.detail"
                                    outlined
                                    label="Детальное описание ресторана"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <div class="elevation-1 pa-4 my-4">
                            <v-row>
                                <v-col class="d-flex justify-space-between align-center">
                                    <span><b>Изображения для галереи</b></span>
                                    <v-btn color="primary" @click="addGaleryPicture">
                                        <v-icon>mdi-plus</v-icon> Добавить картинку
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <template v-for="(picture, index) in style.params.pictures">
                                    <v-col :key="`picture-${index}`" xs="12" sm="6" md="4" lg="3" class="d-flex flex-column justify-space-between">
                                        <span class="mb-4">
                                            <v-img :src="imageSrc(picture)" />
                                        </span>
                                        <span class="d-flex justify-space-between align-center">
                                            <v-file-input
                                                label="Изображение"
                                                outlined
                                                prepend-icon="mdi-image"
                                                hide-details
                                                @change="setGaleryPicture(index, $event)"
                                            />
                                            <v-icon color="error" class="ml-4" @click="removeGaleryPicture(index)">mdi-trash-can</v-icon>
                                        </span>
                                    </v-col>
                                </template>
                            </v-row>
                        </div>
                        <div class="elevation-1 pa-4 my-4">
                            <v-row>
                                <v-col class="d-flex justify-space-between align-center">
                                    <span><b>Блюда</b></span>
                                    <v-btn color="primary" @click="addProduct">
                                        <v-icon>mdi-plus</v-icon> Добавить блюдо
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <template v-for="(product, index) in selectedProducts">
                                    <v-col :key="`product-${index}`" sm="12" md="6" lg="4" class="d-flex flex-column justify-space-between">
                                        <span class="d-flex justify-space-between align-center">
                                            <v-select
                                                v-model="style.params.products[index]"
                                                label="Продукт"
                                                :items="products"
                                                item-text="title"
                                                item-value="id"
                                                outlined
                                                hide-details
                                            />
                                            <v-icon color="error" class="ml-4" @click="removeProduct(index)">mdi-trash-can</v-icon>
                                        </span>
                                    </v-col>
                                </template>
                            </v-row>
                        </div>

                        <div class="elevation-1 pa-4 my-4">
                            <v-row>
                                <v-col class="d-flex justify-space-between align-center">
                                    <span><b>Банкеты</b></span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="style.params.banquets_title"
                                        outlined
                                        label="Заголовок для блока Банкеты"
                                        hide-details
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-img max-width="400px" v-if="style.params.banquets_img" :src="imageSrc(style.params.banquets_img)" class="mb-4" />
                                    <v-file-input
                                        label="Изображение для блока Банкеты"
                                        outlined
                                        prepend-icon="mdi-image"
                                        hide-details
                                        @change="setParamImage('banquets_img', $event)"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-textarea
                                        v-model="style.params.banquets_detail"
                                        outlined
                                        label="Текст для блока Банкеты"
                                        hide-details
                                    />
                                </v-col>
                            </v-row>
                        </div>

                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="style.primary_color"
                                    outlined
                                    label="Основной цвет"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="style.header_bg"
                                    outlined
                                    label="Цвет фона шапки"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="style.button_bg"
                                    outlined
                                    label="Цвет фона кнопок"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="style.button_color"
                                    outlined
                                    label="Цвет шрифта кнопок"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn
                            color="primary"
                            class="mr-3"
                            @click="save"
                        >Сохранить</v-btn>
                        <v-btn
                            color="light"
                            class="mr-3"
                            @click="goBack"
                        >Отмена</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import store from '../../store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import SiteTabLinks from '../../components/sites/SiteTabLinks';

    import { imageSrc, translit } from '../../helpers';

    export default {
        name: 'SiteStyle',

        components: {
            SiteTabLinks,
        },

        data() {
            return {
                imageSrc,
                style: {
                    logo: null,
                    primary_color: null,
                    header_bg: null,
                    button_bg: null,
                    button_color: null,
                    params: {
                        products: [],
                        pictures: [],
                        scheme: null
                    }
                },
                deleteModal: false,

                site_scheme_index: '',
                site_schemes: [
                    ['#FCF1E4', '#A4C081', '#6F5E47', '#F2C94C', '#2C2422'],
                    ['#FFE8F4', '#FAC46B', '#632386', '#F782C2', '#3D1254'],
                    ['#FFFECF', '#FACD60', '#CFA146', '#6CEA9E', '#59441A'],
                    ['#F3F3CF', '#DAD873', '#454D66', '#309975', '#253E46'],
                    ['#E3F6F5', '#C5DA73', '#454D66', '#0F0E30', '#222736'],
                    ['#D9E9E5', '#F9B4AB', '#679186', '#FFDFB4', '#416B60'],
                    ['#82614a', '#d5b77d', '#c58248', '#6c4025', '#4e332c'],
                    ['#412810', '#8d9e3f', '#6c5f49', '#ebcb62', '#2b2422']
                ]
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('sites', {
                entity: state => state.entity
            }),
            ...mapState('siteStyle', {
                oldStyle: state => state.entity
            }),
            ...mapState('products', {
                products: state => state.entities
            }),
            selectedProducts() {
                const products = [];
                if(this.products && this.style && this.style.params && this.style.params.products) {
                    for(const id of this.style.params.products) {
                        products.push(this.products.find(item => item.id === id));
                    }
                }
                return products;
            },
            isAdmin() {
                return this.user.admin;
            },
        },

        async mounted() {
            const { id } = this.$route.params;
            if(id) {
                await store.dispatch('sites/get', {id});
                await store.dispatch('siteStyle/get', { entity_id: id });
                await store.dispatch('products/fetch', {site_id: id});

                this.style = {
                    ...this.style,
                    ...this.oldStyle
                };
                if (!this.style.params) {
                    this.style.params = {};
                }
                if (!this.style.params.products) {
                    this.style.params.products = [];
                }
                if (!this.style.params.pictures) {
                    this.style.params.pictures = [];
                }
                if (this.style.params.scheme) {
                    let schemeIndex = 0;
                    for(const scheme of this.site_schemes) {
                        if(JSON.stringify(scheme) === JSON.stringify(this.style.params.scheme)) {
                            this.site_scheme_index = `${schemeIndex}-scheme`;
                        }
                        schemeIndex++;
                    }
                    if(this.site_scheme_index === '') {
                        this.site_schemes.push(this.style.params.scheme);
                        this.site_scheme_index = `${schemeIndex}-scheme`;
                    }
                    this.style.params.scheme = JSON.stringify(this.style.params.scheme);
                } else {
                    this.site_scheme_index = '0-scheme';
                }
            }
        },

        methods: {
            async setLogo(file) {
                this.style.logo = await store.dispatch('files/upload', { file });
            },
            async setParamImage(paramName, file) {
                this.style.params[paramName] = await store.dispatch('files/upload', { file });
            },
            addGaleryPicture() {
                this.style.params.pictures.push('');
            },
            removeGaleryPicture(index) {
                this.style.params.pictures.splice(index, 1);
            },
            async setGaleryPicture(index, file) {
                this.style.params.pictures[index] = await store.dispatch('files/upload', { file });
                this.style.params.pictures.push('');
                this.style.params.pictures.splice(-1);
            },
            addProduct() {
                this.style.params.products.push('');
            },
            removeProduct(index) {
                this.style.params.products.splice(index, 1);
            },
            async setProduct(index, id) {
                this.style.params.products[index] = id;
                this.style.params.products.push('');
                this.style.params.products.splice(-1);
            },
            async save() {
                const { id } = this.$route.params;
                try {
                    this.style.params.scheme = this.site_schemes[+this.site_scheme_index.split('-')[0]];
                    store.commit('siteStyle/SET_ENTITY', this.style);
                    await store.dispatch('siteStyle/save', { entity_id: id });
                    this.goBack();
                } catch (error) {
                    alert('Ошибка сохранения');
                }
            },
            goBack() {
                const { id } = this.$route.params;
                if(id) {
                    this.$router.push(`/sites/${ id }/detail`);
                } else {
                    this.$router.push('/sites');
                }
            },
        },
    };
</script>
